import { LogDebug } from "../../helpers/LogHelper";
import { createStandaloneToast } from "@chakra-ui/toast";
import { createSlice } from "@reduxjs/toolkit";
import {
  collection,
  getDocs,
  doc,
  serverTimestamp,
  updateDoc,
  query,
  where,
  addDoc,
  and,
} from "firebase/firestore";
import { addUserInformation } from "../user/userSlice";
import { getFirestore } from "../../app/firebase";

const initialState = {
  employers: [],
};

const companySlice = createSlice({
  name: "companySlice",
  initialState,
  reducers: {
    setCompanies(state, action) {
      LogDebug(
        setCompanies.name,
        "Initiated setting employees survey state after loading.",
        action.payload
      );
      Object.assign(state, {
        ...action.payload,
        lastUpdated: new Date(),
        dataAvailable: true,
        loading: false,
      });
    },
    setLoading(state, action) {
      LogDebug(
        setLoading.name,
        "Initiated setting employee survey data in loading state.",
        action.payload
      );
      Object.assign(state, {
        ...action.payload,
        lastUpdated: new Date(),
        dataAvailable: false,
        loading: true,
      });
    },
  },
});

export const fetchAllAvalaibleEmployers = () => {
  return async (dispatch) => {
    const db = getFirestore();
    const employersCollectionQuery = collection(db, "employers");

    const employers = [];

    const snapshot = await getDocs(employersCollectionQuery);

    for (const document of snapshot.docs) {
      const data = document.data();
      employers.push(data);
    }

    dispatch(
      companySlice.actions.setCompanies({
        employers: employers,
      })
    );
  };
};

export const updateCompanyProfile = (companyId, data) => {
  return async (dispatch) => {
    data = { ...data, lastUpdated: serverTimestamp() };
    const db = getFirestore();

    const employersRef = collection(db, "employers");
    const employerQ = query(employersRef, where("employerId", "==", companyId));

    const employerQuerySnapshot = await getDocs(employerQ);

    if (
      !employerQuerySnapshot.empty &&
      employerQuerySnapshot.docs.length === 1
    ) {
      const employerRef = doc(
        db,
        "employers",
        employerQuerySnapshot.docs[0].id
      );
      await updateDoc(employerRef, { ...data, lastUpdated: serverTimestamp() });
    }

    if (data.name) {
      await dispatch(
        addUserInformation({
          employer: { ...data },
        })
      );
    }
  };
};

export const fetchEmployer = async (employerId) => {
  const db = getFirestore();
  const employersRef = collection(db, "employers");
  const employerQuery = query(
    employersRef,
    where("employerId", "==", employerId)
  );

  const employerQuerySnapshot = await getDocs(employerQuery);

  if (employerQuerySnapshot.empty) {
    return undefined;
  } else {
    const employerDoc = await employerQuerySnapshot.docs[0];
    const employerData = employerDoc.data();
    return { data: employerData, id: employerDoc.id };
  }
};

/**
 *
 * Keep fetchEmployeesAndSetData and fecthAllEmployees in sync.
 * @param {string} employerId
 */
export const fetchAllEmployeesByDepartment = async (
  employerId,
  departmentId
) => {
  const db = getFirestore();
  const employeesRef = collection(db, "users");
  const employeeRefQuery = query(
    employeesRef,
    and(
      where("employerId", "==", employerId),
      where("departmentId", "==", departmentId)
    )
  );

  const querySnapshot = await getDocs(employeeRefQuery);

  const employees = [];
  for (const employee of querySnapshot.docs) {
    employees.push(await employee.data());
  }

  console.log(employees);

  return employees;
};

export const fetchDepartments = async (
  employerDocId,
  employerId,
  getDepartmentCount = false
) => {
  const db = getFirestore();
  const employersDepartmentRef = collection(
    db,
    `employers/${employerDocId}/departments`
  );

  const allDepartmentSnapshot = await getDocs(employersDepartmentRef);
  const departments = [];

  for (const departmentSnapshot of allDepartmentSnapshot.docs) {
    const department = await departmentSnapshot.data();
    // get count of employees in the departments
    let count = undefined;
    if (getDepartmentCount) {
      count = (await fetchAllEmployeesByDepartment(employerId, department.id))
        .length;
    }

    departments.push({ ...department, count });
  }

  return departments;
};

export const createNewDepartment = async (employerDocId, department) => {
  const db = getFirestore();
  const employersDepartmentRef = collection(
    db,
    `employers/${employerDocId}/departments`
  );

  await addDoc(employersDepartmentRef, {
    ...department,
    created: serverTimestamp(),
    lastUpdated: serverTimestamp(),
  });
};

export const updateExistingDepartment = async (employerDocId, department) => {
  const db = getFirestore();
  const { toast } = createStandaloneToast();
  const employersDepartmentRef = collection(
    db,
    `employers/${employerDocId}/departments`
  );

  const existingDepartmentSnapshot = await getDocs(
    query(employersDepartmentRef, where("id", "==", department.id))
  );

  if (existingDepartmentSnapshot.empty) {
    toast({
      title: "Could not find department details.",
      status: "error",
      isClosable: true,
    });
    return;
  }

  if (existingDepartmentSnapshot.docs.length !== 1) {
    toast({
      title: "Found multiple department details. Only one can exist",
      status: "error",
      isClosable: true,
    });
    return;
  }

  const departmentDocPath = existingDepartmentSnapshot.docs[0].ref.path;
  const departmentDoc = doc(db, departmentDocPath);

  await updateDoc(departmentDoc, { ...department });
};

export const { setCompanies, setLoading } = companySlice.actions;

export default companySlice.reducer;
